import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { getResortData } from "../services/dataServices";        
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Collapse from 'react-bootstrap/Collapse';
import Modal from 'react-bootstrap/Modal';
import { IoCloseOutline } from "react-icons/io5";
import Scrollbars from "react-custom-scrollbars";

function BookNow() {

 let currentDateTime = new Date();
  const currentDate = currentDateTime.getDate();
  const nextDate = new Date(currentDateTime.setDate(currentDate+1))
  const [bookingStartDate, setStart] = useState(new Date());
  const [bookingEndDate, setEnd] = useState(nextDate);

 
  const [selectedItemResort, setSelectedItem] = useState();
  const allResortdata = useQuery('allresortdata',getResortData)


   /* when getting error from resort API than this static data use in else condition */
  const resortData = allResortdata?.data?.data == null ? [
  { brand_resort_id: 1, resort_code: "95216", resort_name: "Atmosphere - Kanifushi" },
   { brand_resort_id: 2, resort_code: "110184", resort_name: "By Atmosphere - Varu" },
] : allResortdata.data.data;

const [open, setOpen] = useState(false);

  // const handleSelectChange = (event) => {
  //   const selectedItemCode = event.target.value;
  //   const selectedItemResort = resortData.find(
  //     (item) => item.resort_code === selectedItemCode
  //   );
  //   setSelectedItem(selectedItemResort);

  //   setFormData({
  //     ...formData,
  //     resort: selectedItemCode,
  //   });
  // };

  const [formData, setFormData] = useState({
    resort: 110184,
    discountCode: "",
    iataCode: "",
    adultCount: "2",
    roomCount: "1",
    childrenCount: 0,
    childrenAges: Array(0).fill(6),
  });


  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, adultCount: +formData.adultCount + 1 });
  };

  const adultDecrementBtn = (e) => {
    e.preventDefault();
    if (formData.adultCount > 1) {
      setFormData({ ...formData, adultCount: +formData.adultCount - 1 });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setFormData({ ...formData, childrenCount: +formData.childrenCount + 1 });
  };

  const childrenDecrement = (event) => {
    event.preventDefault();
    if (formData.childrenCount > 0) {
      setFormData({ ...formData, childrenCount: +formData.childrenCount - 1 });
    }
  };
  /* End children count*/

  /* Start Room count*/

  const roomIncrementBtn = (event) => {
    event.preventDefault();
    setFormData({ ...formData, roomCount: +formData.roomCount + 1 });
  };

  const roomDecrementBtn = (event) => {
    event.preventDefault();
    if (formData.roomCount > 1) {
      setFormData({ ...formData, roomCount: +formData.roomCount - 1 });
    }
  };
  /* End Room count*/

  const handleDiscountCodeChange = (event) => {
    setFormData({
      ...formData,
      discountCode: event.target.value,
    });
  };
  const handleIataCodeChange = (event) => {
    setFormData({
      ...formData,
      iataCode: event.target.value,
    });
  };

  // Format date to MM-DD-YYYY
  const formatDate = (date) => format(date, "MM/dd/yyyy");

  /*Submit form Action Start here */
  const handleSubmit = (event) => {
    event.preventDefault();

    // Access formData for submission or further processing

    const queryParams = new URLSearchParams();
    // queryParams.set('resortId',formData.resort)
    queryParams.set("adults", formData.adultCount);
    queryParams.set("children", formData.childrenCount);
    queryParams.set("childage", formData.childrenAges);
    queryParams.set("datein", formatDate(bookingStartDate));
    queryParams.set("dateOut", formatDate(bookingEndDate));
    queryParams.set("rooms", formData.roomCount);
    queryParams.set("discount", formData.discountCode);
    queryParams.set("iata", formData.iataCode);

    const newTab = window.open(
      `https://reservations.travelclick.com/${
        formData.resort
      }?${queryParams.toString()}`,
      "_blank"
    );
    // Focus on the new tab (optional)
    if (newTab) {
      newTab.focus();
    }

    // if (formData.resort > 0) {
    //   const newTab = window.open(
    //     `https://reservations.travelclick.com/${
    //       formData.resort
    //     }?${queryParams.toString()}`,
    //     "_blank"
    //   );
    //   // Focus on the new tab (optional)
    //   if (newTab) {
    //     newTab.focus();
    //   }
    // } else {
    //   const newTab = window.open(
    //     `https://search.travelclick.com/ahr/`,
    //     "_blank"
    //   );
    //   // Focus on the new tab (optional)
    //   if (newTab) {
    //     newTab.focus();
    //   }
    // }
  };
  /**End Submit form here **/

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (show) {
      document.body.classList.add("guestmodal-open-body"); // Add class when modal is open
    } else {
      document.body.classList.remove("guestmodal-open-body"); // Remove class when modal is closed
    }

    // Cleanup to ensure no class remains if the component unmounts
    return () => {
      document.body.classList.remove("guestmodal-open-body");
    };
  }, [show]);

  const handleChildrenCountChange = (event, increment, fieldName) => {
    event.preventDefault();

    setFormData((prevFormData) => {
      const currentCount = Number(prevFormData[fieldName]) || 0;
      // const newCount = Math.max(0, Math.min(11, currentCount + increment)); // Enforce range [0, 11]
      const newCount = Math.max(0, currentCount + increment);

      // Adjust the childrenAges array to match the new count
      const updatedChildAges = [...prevFormData.childrenAges];
      if (newCount > currentCount) {
        // Add new entries for additional children
        for (let i = currentCount; i < newCount; i++) {
          updatedChildAges.push(6); // Default age to 6 for new children
        }
      } else if (newCount < currentCount) {
        // Remove extra entries
        updatedChildAges.splice(newCount);
      }

      return {
        ...prevFormData,
        [fieldName]: newCount,
        childrenAges: updatedChildAges,
      };
    });
  };

  const handleChildAgeChange = (index, increment) => {
    setFormData((prevFormData) => {
      const newChildrenAges = [...prevFormData.childrenAges];
      const newAge = Math.max(0, Math.min(11, newChildrenAges[index] + increment)); // Enforce age range [0, 11]
      newChildrenAges[index] = newAge;
      return { ...prevFormData, childrenAges: newChildrenAges };
    });
  };

  // const [paddingRight, setPaddingRight] = useState(0);

  // const handleUpdate = ({ scrollHeight, clientHeight }) => {
  //   // Check if the scrollbar is visible
  //   if (scrollHeight > clientHeight) {
  //     setPaddingRight(16); // Width of the scrollbar (adjust as needed)
  //   } else {
  //     setPaddingRight(0); // No scrollbar, remove padding
  //   }
  // };
  const [paddingRight, setPaddingRight] = useState(0);
  const scrollbarRef = useRef(null); // Reference to the scrollbar
  const [hasScrolled, setHasScrolled] = useState(false); // To track if we've scrolled initially

  const datepicker = document.querySelector(".daterangepicker");
  const drpButtons = document.querySelector(".daterangepicker .drp-buttons");

  if (drpButtons) {
    drpButtons.classList.add("d-none");
  }
  const handleUpdate = ({ scrollHeight, clientHeight }) => {
    // Check if the scrollbar is visible
    if (scrollHeight > clientHeight) {
      setPaddingRight(8); // Width of the scrollbar (adjust as needed)
    } else {
      setPaddingRight(0); // No scrollbar, remove padding
    }

    const scrollbar = scrollbarRef.current;
    if (scrollbar && !hasScrolled) {
      const scrollHeight = scrollbar.getScrollHeight();
      const clientHeight = scrollbar.getClientHeight();

      // Check if scrolling is possible (content exceeds the visible area)
      if (scrollHeight > clientHeight) {
        scrollbar.scrollToBottom();
        setHasScrolled(true); // Ensure it only happens the first time
      }
    }
  };

  const datepickerRef = useRef(null); // Ref to access the datepicker element
  // const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [openCount, setOpenCount] = useState(0); // Track the number of times the picker is opened
  const [selectedValue, setSelectedValue] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null); // Track the open dropdown
  const [clickedChild, setClickedChild] = useState(null);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const parent = document.querySelector(".parent");
  const handleChildClick = (childIndex) => {
    //console.log("clicked child")
    setClickedChild(childIndex);
    setOpenDropdown(openDropdown === childIndex ? null : childIndex); // Toggle dropdown open/close
    if (datepickerRef.current && childIndex != 2) {
      datepickerRef.current.ref.classList.remove("datepicker-open");
    }
    // if(childIndex === 3) setShow(true)
    if (childIndex != 2) {
      setIsDateOpen(false);
    }
    //console.log("first")
    //console.log("isDateOpen",isDateOpen)
    if (datepicker && !isDateOpen) {
      //console.log("reached add")
      //console.log("datepicker.classList 1",datepicker.classList)
      datepicker.classList.add("show-calendar");
      //console.log("datepicker.classList 2",datepicker.classList)
    } else if (datepicker && isDateOpen) {
      //console.log("reached remove")
      //console.log("datepicker.classList 3",datepicker.classList)

      datepicker.classList.remove("show-calendar");
      //console.log("datepicker.classList 4",datepicker.classList)
    }
    if (childIndex != 4) {
      setShow(false);
    }
    if (childIndex === 3 && isMobile) setShow(true);
    if (childIndex === 2) {
      if (!isDateOpen && isTabMobile) {
        console.log("not open");
        setIsDateOpen(true);
        datepickerRef.current.ref.classList.add("datepicker-open");
        // parent.classList.remove('highlight');
      } else if (isDateOpen && isTabMobile) {
        console.log("open");
        console.log("parent.classList", parent.classList);
        if (isTabMobile) setIsDateOpen(false);
        datepickerRef.current.ref.classList.remove("datepicker-open");
        //parent.classList.remove('highlight');
      }
    }
  };
  const handleDateShow = (e) => {
    if (datepickerRef.current) {
      datepickerRef.current.ref.classList.add("datepicker-open");
    }
    //console.log("second")

    const newOpenCount = openCount + 1;
    setOpenCount(newOpenCount);

    if (newOpenCount === 3) {
      // Reset dates on the third open
      setStart(new Date()); // Reset to current date or desired default
      setEnd(new Date());
      setOpenCount(0); // Reset the count
    }
  };

  const handleHide = () => {};
  const [isMenuOpen, setMenuOpen] = useState(false);

  const datepickerrefelement = document.querySelector(".checkin-out-date");
  const addGuestselement = document.querySelector(".checkin-out-date");
  const handleGuestsSubmit = (e) => {
    ////console.log("clicked add")
    if (!isMenuOpen) setMenuOpen(true); //setMenuOpen(true)
    setMenuOpen2(false);
    ////console.log("isMenuOpeninnerclick",isMenuOpen)
    e.stopPropagation();
    if (isMobile) setShow(false);
  };

  useEffect(() => {
    if (datepicker) {
      //console.log("defaulterrrrrrrrrrrrrrrrrr")
      datepicker?.classList.remove("show-calendar");
    }
  }, []);

  // Add event listener to the document
  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (!parent) return; // Exit early if parent is undefined
      ////console.log("enetered start")
      const target = event.target;

      // Check if the target is inside the specific elements
      const isInsideFirst = target.closest(".firstelement");
      const isInsideSecond = target.closest(".secondelement");
      const isInsideThird = target.closest(".thirdelement");
      const isInsideFourth = target.closest(".fourthelement");

      // Highlight logic
      if (isInsideFirst || isInsideSecond || isInsideThird || isInsideFourth) {
        if (!isTabMobile) parent.classList.add("highlight");
      } else {
        if (!isTabMobile) parent.classList.remove("highlight");

        // Handle datepicker closing logic
        if (datepickerRef?.current?.ref?.classList) {
          datepickerRef.current.ref.classList.remove("datepicker-open");
        }
      }
      ////console.log("isMenuOpeninner",isMenuOpen)
      ////console.log("enetered close")
      // Handle menu open/close states
      if (!isInsideFirst) setIsOpen(false);
      if (!isInsideThird && !isInsideFourth) {
        setMenuOpen2(false);
        if (isMenuOpen) {
          ////console.log("andar waya")
          setMenuOpen(false);
        }
      }
      if (isInsideFourth) {
      }
    });
  }, [parent, isMenuOpen]);

  // Close dropdown if clicking outside or on a different child div
  const handleClickOutside = (e) => {
    if (!e.target.closest(".child")) {
      setOpenDropdown(null); // Close all dropdowns when clicking outside
    }
  };

  ////console.log("datepicker.classList",datepicker?.classList)

  React.useEffect(() => {
    // Listen for outside clicks to close dropdown
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelectChange = (e, option) => {
    setSelectedValue(option.resort_name);

    const selectedItemCode = option.resort_code;
    const selectedItemResort = resortData.find(
      (item) => item.resort_code === selectedItemCode
    );
    setSelectedItem(selectedItemResort);

    setFormData({
      ...formData,
      resort: selectedItemCode,
    });
    setIsOpen(false);

    if (datepickerRef?.current?.ref?.classList) {
      datepickerRef.current.ref.classList.add("datepicker-open");
    }
    if (datepickerrefelement) {
      datepickerrefelement.click(); // Automatically trigger apply on show
    }
    if (!isTabMobile) parent.classList.add("highlight");
    e.stopPropagation();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    if (isTabMobile) setIsOpen(!isOpen);
    else setIsOpen(true);
  };

  const toggleMenu = () => {
    if (isTabMobile) setMenuOpen(!isMenuOpen);
    else {
      setMenuOpen(true);
    }
    setMenuOpen2(false);
  };
  const [isMenuOpen2, setMenuOpen2] = useState(false);

  const toggleMenu2 = () => {
    if (isTabMobile) setMenuOpen2(!isMenuOpen2);
    else {
      setMenuOpen2(true);
    }
    setMenuOpen(false);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isTabMobile, setIsTabMobile] = useState(window.innerWidth <= 1399);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      setIsTabMobile(window.innerWidth <= 1399);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  return (
    <>
      <section id="BookNow">
        <div className="atmosphere-book-now bg-white rounded-1 ms-auto me-auto z-1 position-relative">
          <form action="#" method="get">
            <div className="row parent align-items-start xxl-row-gap-0 row-gap-3">
              
              {/* /col */}
              <div className="col-xxl-3 col-md-6 date-parent ahr-datepicker-wrapper child ">
                <Form.Label
                  htmlFor="Resort"
                  className="text-uppercase opacity-75"
                >
                  Check in <span className="mx-1">-</span> Check out
                </Form.Label>
                <DateRangePicker
                  ref={datepickerRef}
                  initialSettings={{
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                    startDate: bookingStartDate,
                    endDate: bookingEndDate,
                    minDate: new Date(),
                    autoApply: true,
                    parentEl: ".date-parent", // Appends the datepicker to the parent container
                    alwaysShowCalendars: true,
                  }}
                  onApply={(event, picker) => {
                    ////console.log("reached show model")
                    if (picker.startDate && picker.endDate) {
                      setStart(new Date(picker.startDate));
                      setEnd(new Date(picker.endDate));
                      if (datepickerRef?.current?.ref?.classList) {
                        datepickerRef.current.ref.classList.remove(
                          "datepicker-open"
                        );
                      }
                      setMenuOpen2(true);

                      if (isMobile) setShow(true);
                      // if (show) {
                      //   document.body.classList.add("guestmodal-open-body"); // Add class when modal is open
                      // } else {
                      //   document.body.classList.remove("guestmodal-open-body"); // Remove class when modal is closed
                      // }
                      // ////console.log("reached apply date")
                    }
                  }}
                  onShow={(e) => handleDateShow(e)} // Triggered when the datepicker is opened
                  onHide={handleHide} // Triggered when the datepicker is closed
                >
                  <input
                    type="button"
                    className="form-control shadow-none text-start form-select checkin-out-date cstm-arrow secondelement"
                    onClick={(e) => handleChildClick(2)}
                  />
                </DateRangePicker>
              </div>
              {/* /col */}
              <div className="col-xxl col-md-6 child ">
                <div className="booking-form-input guests-input position-relative d-md-none d-block">
                  <Button
                    variant="primary"
                    className="border-0 bg-transparent shadow-none p-0 bg-transparent mob-arrow"
                  >
                    <Form.Label
                      htmlFor="Resort"
                      className="form-label text-uppercase opacity-75 form-select-sm p-0"
                    >
                      Guests
                    </Form.Label>
                    <div
                      name="guests-btn"
                      className="form-control mb-md-0 shadow-none guest-form-control position-relative bg-transparent thirdelement downArrow"
                      onClick={(e) => handleChildClick(3)}
                    >
                      {formData.roomCount} Room, {formData.adultCount} Adults,{" "}
                      {formData.childrenCount} Children
                    </div>
                  </Button>
                </div>
                <div className="booking-form-input guests-input position-relative d-md-block d-none">
                  <Form.Label
                    htmlFor="Resort"
                    className="form-label text-uppercase opacity-75 form-select-sm p-0"
                  >
                    Guests
                  </Form.Label>

                  {/* bhubesh */}
                  <div
                    className="dropdown-container thirdelement"
                    onClick={(e) => handleChildClick(3)}
                  >
                    {/* Dropdown Toggle */}
                    <div
                      className="form-control mb-md-0 shadow-none guest-form-control cstm-arrow downArrow"
                      id="guests-input-btn"
                      onClick={toggleMenu2}
                      style={{ cursor: "pointer" }}
                    >
                      {formData.roomCount}{" "}
                      {formData.roomCount === 1 ? "Room" : "Rooms"},{" "}
                      {formData.adultCount}{" "}
                      {formData.adultCount === 1 ? "Adult" : "Adults"},{" "}
                      {formData.childrenCount}{" "}
                      {formData.childrenCount === 1 ? "Child" : "Children"}
                    </div>

                    {/* Dropdown Menu */}
                    {isMenuOpen2 && (
                      <div className="guest-dropdown childs-dropdown position-relative shadow-none mw-100 w-100 mt-1 rounded-1">
                        <Scrollbars
                          autoHeight
                          autoHeightMax={200}
                          onUpdate={handleUpdate}
                          ref={scrollbarRef}
                        >
                          <div
                            className="w-full overflow-hidden"
                            style={{ paddingRight: `${paddingRight}px` }}
                          >
                            <div className="row">
                              <div className="mb-4">
                                <div
                                  className="guests-input_options bg-white"
                                  id="guests-input-options"
                                >
                                  {/* Rooms Section */}
                                  <div className="guest-dropdown-item">
                                    <div className="row align-items-center">
                                      <div className="col-xxl-4 col-xl-2 col-md-6">
                                        <label>Rooms</label>
                                      </div>
                                      <div className="col-xxl-8 col-xl-10 col-md-6">
                                        <div className="guest-card d-flex align-items-center">
                                          <span
                                            className="guests-input__ctrl minus"
                                            onClick={roomDecrementBtn}
                                          >
                                            <FontAwesomeIcon
                                              icon={faAngleLeft}
                                            />
                                          </span>
                                          <span className="guests-input__value w-100">
                                            <input
                                              type="hidden"
                                              value={formData.roomCount}
                                            />
                                            {formData.roomCount}
                                          </span>
                                          <span
                                            className="guests-input__ctrl plus"
                                            onClick={roomIncrementBtn}
                                          >
                                            <FontAwesomeIcon
                                              icon={faAngleRight}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Adults Section */}
                                  <div className="row align-items-center my-3">
                                    <div className="col-xxl-4 col-xl-2 col-md-6">
                                      <label>Adults</label>
                                    </div>
                                    <div className="col-xxl-8 col-xl-10 col-md-6">
                                      <div className="guest-card d-flex align-items-center">
                                        <span
                                          className="guests-input__ctrl minus"
                                          onClick={adultDecrementBtn}
                                        >
                                          <FontAwesomeIcon icon={faAngleLeft} />
                                        </span>
                                        <span className="guests-input__value w-100 text-center">
                                          <input
                                            type="hidden"
                                            value={formData.adultCount}
                                          />
                                          {formData.adultCount}
                                        </span>
                                        <span
                                          className="guests-input__ctrl plus"
                                          onClick={adultIncrementBtn}
                                        >
                                          <FontAwesomeIcon
                                            icon={faAngleRight}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Children Section */}
                                  <div className="guest-dropdown-item">
                                    <div className="row align-items-center">
                                      <div className="col-xxl-4 col-xl-2 col-md-6">
                                        <label>Children</label>
                                      </div>
                                      <div className="col-xxl-8 col-xl-10 col-md-6">
                                        <Button
                                          className="p-0 border-0 bg-transparent arrow-hide text-center w-100"
                                          //onClick={() => setMenuOpen2(!isMenuOpen2)}
                                        >
                                          <div className="guest-card d-flex align-items-center">
                                            <span
                                              className="guests-input__ctrl minus"
                                              onClick={(e) =>
                                                handleChildrenCountChange(
                                                  e,
                                                  -1,
                                                  "childrenCount"
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faAngleLeft}
                                              />
                                            </span>
                                            <span className="guests-input__value w-100">
                                              <input
                                                type="hidden"
                                                value={formData.childrenCount}
                                              />
                                              {formData.childrenCount}
                                            </span>
                                            <span
                                              className="guests-input__ctrl plus"
                                              onClick={(e) =>
                                                handleChildrenCountChange(
                                                  e,
                                                  +1,
                                                  "childrenCount"
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faAngleRight}
                                              />
                                            </span>
                                          </div>
                                        </Button>
                                      </div>
                                    </div>

                                    {/* Children Ages */}
                                    <div className="mt-3">
                                      {formData?.childrenAges?.length > 0 && (
                                        <label>Child Age</label>
                                      )}
                                      <div className="row g-3">
                                        {formData?.childrenAges?.map(
                                          (age, index) => (
                                            <div
                                              className="col-xxl-6 col-xl-6 col-md-6 col-sm-12"
                                              key={index}
                                            >
                                              <div className="guest-card w-100 d-flex align-items-center">
                                                <span
                                                  className="guests-input__ctrl minus"
                                                  onClick={() =>
                                                    handleChildAgeChange(
                                                      index,
                                                      -1
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faAngleLeft}
                                                  />
                                                </span>
                                                <span className="guests-input__value w-100">
                                                  <input
                                                    type="hidden"
                                                    value={age}
                                                  />
                                                  {age}
                                                </span>
                                                <span
                                                  className="guests-input__ctrl plus"
                                                  onClick={() =>
                                                    handleChildAgeChange(
                                                      index,
                                                      1
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faAngleRight}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Scrollbars>

                        <div className="ahr-guests-save-btn">
                          <Button
                            type="button"
                            variant="primary theme-btn fw-medium text-white text-uppercase border-theme"
                            onClick={(e) => handleGuestsSubmit(e)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      name="guests-btn"
                      className="form-control mb-md-0 shadow-none guest-form-control"
                      id="guests-input-btn"
                    >
                      {formData.roomCount} {formData.roomCount == 1 ? 'Room' : 'Rooms'},{formData.adultCount} {formData.adultCount == 1 ? 'Adult' : 'Adults'} , {formData.childrenCount}{" "}
                      {formData.childrenCount == 1 ? 'Child' : 'Children'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="guest-dropdown childs-dropdwon position-relative shadow-none mw-100">
                      <div className="row">
                        <div className="mb-4">
                        
                          <div
                            className="guests-input_options bg-white"
                            id="guests-input-options"
                          >

                            <div className="guest-dropdown-item">
                              <div className="row align-items-center">
                                <div className="col-xxl-4 col-xl-12 col-md-6">
                                  <label>Rooms</label>
                                </div>
                                <div className="col-xxl-8 col-xl-12 col-md-6">
                                  <div className="guest-card d-flex align-items-center">
                                    <span
                                      className="guests-input__ctrl minus"
                                      id="adults-subs-btn"
                                      onClick={roomDecrementBtn}
                                    >
                                      <FontAwesomeIcon icon={faAngleLeft} />
                                    </span>
                                  
                                    <span className="guests-input__value w-100">
                                      <span id="guests-count-adults">
                                        <input
                                          type="hidden"
                                          value={formData.roomCount}
                                        />
                                        {formData.roomCount}
                                      </span>
                                    </span>
                                   
                                    <span
                                      className="guests-input__ctrl plus"
                                      id="adults-add-btn"
                                      onClick={roomIncrementBtn}
                                    >
                                      <FontAwesomeIcon icon={faAngleRight} />
                                    </span>
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center my-3">
                                <div className="col-xxl-4 col-xl-12 col-md-6">
                                  <label>Adults</label>
                                </div>
                                <div className="col-xxl-8 col-xl-12 col-md-6">
                                  <div className="guest-card d-flex align-items-center">
                                    <span
                                      className="guests-input__ctrl minus"
                                      id="adults-subs-btn"
                                      onClick={adultDecrementBtn}
                                    >
                                      <FontAwesomeIcon icon={faAngleLeft} />
                                    </span>
                                   
                                    <span className="guests-input__value w-100">
                                      <span id="guests-count-adults">
                                        <input
                                          type="hidden"
                                          value={formData.adultCount}
                                        />
                                        {formData.adultCount}
                                      </span>
                                    </span>
                                   
                                    <span
                                      className="guests-input__ctrl plus"
                                      id="adults-add-btn"
                                      onClick={adultIncrementBtn}
                                    >
                                      <FontAwesomeIcon icon={faAngleRight} />
                                    </span>
                                  
                                  </div>
                                </div>
                              </div>

                              <div className="guest-dropdown-item">
                                <div className="row align-items-center">
                                  <div className="col-xxl-4 col-xl-12 col-md-6">
                                    <label>Children</label>
                                  </div>
                                  <div className="col-xxl-8 col-xl-12 col-md-6">
                                    <Button
                                      onClick={() => setOpen(!open)}
                                      aria-controls="example-collapse-text"
                                      
                                      className="p-0 border-0 bg-transparent arrow-hide text-center w-100"
                                    >
                                      <div className="guest-card d-flex align-items-center">
                                        <span
                                          className="guests-input__ctrl minus"
                                          id="children-subs-btn"
                                          onClick={(e) => handleChildrenCountChange(e, -1, "childrenCount")}
                                        >
                                          <FontAwesomeIcon icon={faAngleLeft} />
                                        </span>
                                       
                                        <span className="guests-input__value w-100">
                                          <span id="guests-count-children">
                                            <input
                                              type="hidden"
                                              value={formData.childrenCount}
                                            />
                                            {formData.childrenCount}
                                          </span>
                                        </span>
                                        
                                        <span
                                          className="guests-input__ctrl plus"
                                          id="children-add-btn"
                                          onClick={(e) => handleChildrenCountChange(e, +1, "childrenCount")}
                                        >
                                          <FontAwesomeIcon icon={faAngleRight} />
                                        </span>
                                        
                                      </div>
                                    </Button>
                                  </div>
                                </div>

                                
                                <div className="mt-3" id="example-collapse-text">
                                  <Scrollbars autoHeight autoHeightMax={150} onUpdate={handleUpdate}>
                                  <div className="w-full overflow-hidden" style={{ paddingRight: `${paddingRight}px`,}}>
                                  {formData?.childrenAges?.length > 0 && (
                                    <label>Child Age</label>
                                  )}
                                  <div className="row g-3">
                                    {formData?.childrenAges?.map((age, index) => (
                                      <div className="col-xxl-6 col-xl-12 col-md-6" key={index}>
                                        <div className="guest-card w-100 d-flex align-items-center">
                                          <span
                                            className="guests-input__ctrl minus"
                                            id="children-subs-btn"
                                            onClick={() => handleChildAgeChange(index, -1)}><FontAwesomeIcon icon={faAngleLeft} /></span>
                                         

                                          <span className="guests-input__value w-100">
                                            <span id="guests-count-children">
                                             
                                              <input
                                                type="hidden"
                                                value={age}
                                              />
                                              {age}
                                            </span>
                                          </span>
                                         
                                          <span
                                            className="guests-input__ctrl plus"
                                            id="children-add-btn"
                                            onClick={() => handleChildAgeChange(index, 1)}> <FontAwesomeIcon icon={faAngleRight} /></span>
                                        </div>
                                      </div>

                                    ))}

                                  </div>
                                  </div>
                                  </Scrollbars>
                                </div>
                                

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                   
                  </Dropdown> */}
                </div>

                {/* /booking-form-input */}
              </div>
              {/* /col */}
              <div className="col-xxl col-md-6 child ">
                <div className="discount-wrap">
                  <Form.Label
                    htmlFor="DiscountCodes"
                    className="form-label opacity-75 text-uppercase"
                  >
                    Discount Codes
                  </Form.Label>
                  <div
                    className="dropdown-container fourthelement"
                    onClick={(e) => handleChildClick(4)}
                  >
                    <div
                      className="form-control form-select bg-transparent shadow-none cstm-arrow"
                      id="dropdown-toggle"
                      onClick={toggleMenu}
                      style={{ cursor: "pointer" }}
                    >
                      Select Discount
                    </div>

                    {isMenuOpen && (
                      <div className="discount-dropdown guest-dropdown position-relative w-100 mt-1 px-3 shadow-none rounded-1">
                        <div className="row mt-4">
                          <div className="mb-4 d-flex align-items-center justify-content-between xl:flex-nowrap flex-wrap">
                            <Form.Label
                              htmlFor="DiscountCode"
                              className="text-uppercase opacity-75 mb-0 me-2 my-1"
                            >
                              Discount Code
                            </Form.Label>
                            <Form.Control
                              value={formData.discountCode}
                              type="text"
                              className="form-control shadow-none"
                              id="DiscountCode"
                              aria-describedby="passwordHelpBlock"
                              onChange={handleDiscountCodeChange}
                            />
                          </div>
                          <div className="mb-4 d-flex align-items-center justify-content-between xl:flex-nowrap flex-wrap">
                            <Form.Label
                              htmlFor="IATACode"
                              className="text-uppercase opacity-75 mb-0 me-2 my-1"
                            >
                              IATA Code
                            </Form.Label>
                            <Form.Control
                              value={formData.iataCode}
                              type="text"
                              className="form-control shadow-none"
                              id="IATACode"
                              aria-describedby="passwordHelpBlock"
                              onChange={handleIataCodeChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      className="form-control form-select bg-transparent shadow-none"
                      id="dropdown-basic"
                    >
                      Select Discount
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="discount-dropdown position-relative">
                      <div className="row">
                        <div className="mb-4 d-flex align-items-center justify-content-between">
                          <Form.Label
                            htmFor="DiscountCode"
                            className="text-uppercase opacity-75 mb-0"
                          >
                            Discount Code
                          </Form.Label>
                          <Form.Control
                            value={formData.discountCode}
                            type="text"
                            className="form-control shadow-none"
                            id="DiscountCode"
                            aria-describedby="passwordHelpBlock"
                            onChange={handleDiscountCodeChange}
                          />
                        </div>
                        <div className="mb-4 d-flex align-items-center justify-content-between">
                          <Form.Label
                            htmlFor="IATACode"
                            className="text-uppercase opacity-75 mb-0"
                          >
                            IATA Code
                          </Form.Label>
                          <Form.Control
                            value={formData.iataCode}
                            type="text"
                            className="form-control shadow-none"
                            id="IATACode"
                            aria-describedby="passwordHelpBlock"
                            onChange={handleIataCodeChange}
                          />
                        </div>
                        
                      </div>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
              </div>
              {/* /col */}
              <div
                className="col-xl-12 col-md-6 submit-col child"
                onClick={(e) => handleChildClick(5)}
              >
                <div className="submit-form">
                  <Button
                    type="submit"
                    variant="primary theme-btn fw-medium text-white text-uppercase border-theme"
                    onClick={handleSubmit}
                  >
                    Check
                  </Button>
                </div>
              </div>
              {/* /col */}
            </div>
            {/* /row */}
          </form>
        </div>
        <Modal
          className="guestmodal"
          show={show}
          onHide={handleClose}
          animation={true}
          backdropClassName="guest-backdrop overflow-hidden"
        >
          <Modal.Header className="border-0 pb-0">
            <div className="d-flex justify-content-end w-100">
              <Button
                variant="secondary"
                className="p-0 bg-transparent text-black shadow-none border-0 fs-2 ms-auto"
                onClick={handleClose}
              >
                <IoCloseOutline />
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 pb-4 guest-dropdown border-0">
            <Scrollbars
              autoHide={false}
              autoHeight
              autoHeightMax={200}
              style={{ height: "200px", width: "100%" }}
              onUpdate={handleUpdate}
              ref={scrollbarRef}
              className="sc"
            >
              <div className="guests-input_options mios w-full overflow-hidden ">
                <div className="guest-dropdown-item px-0 ">
                  <div className="row align-items-center">
                    <div className="col-6 text-start">
                      <label>Rooms</label>
                    </div>
                    <div className="col-6">
                      <div className="guest-card d-flex align-items-center">
                        <span
                          className="guests-input__ctrl minus"
                          id="adults-subs-btn"
                          onClick={roomDecrementBtn}
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        {/* /.guests-input__ctrl */}
                        <span className="guests-input__value w-100">
                          <span id="guests-count-adults">
                            <input type="hidden" value={formData.roomCount} />
                            {formData.roomCount}
                          </span>
                        </span>
                        {/* /.guests-input__value */}
                        <span
                          className="guests-input__ctrl plus"
                          id="adults-add-btn"
                          onClick={roomIncrementBtn}
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                        {/* /.guests-input__ctrl */}
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center my-3">
                    <div className="col-6 text-start">
                      <label>Adults</label>
                    </div>
                    <div className="col-6">
                      <div className="guest-card d-flex align-items-center">
                        <span
                          className="guests-input__ctrl minus"
                          id="adults-subs-btn"
                          onClick={adultDecrementBtn}
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        {/* /.guests-input__ctrl */}
                        <span className="guests-input__value w-100">
                          <span id="guests-count-adults">
                            <input type="hidden" value={formData.adultCount} />
                            {formData.adultCount}
                          </span>
                        </span>
                        {/* /.guests-input__value */}
                        <span
                          className="guests-input__ctrl plus"
                          id="adults-add-btn"
                          onClick={adultIncrementBtn}
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                        {/* /.guests-input__ctrl */}
                      </div>
                    </div>
                  </div>
                  <div className="guest-dropdown-item px-0 pt-0">
                    <div className="row align-items-center">
                      <div className="col-6 text-start">
                        <label>Children</label>
                      </div>
                      <div className="col-6">
                        <Button
                          // onClick={() => setOpen(!open)}
                          // aria-controls="example-collapse-text"
                          // aria-expanded={open}
                          className="p-0 border-0 bg-transparent arrow-hide text-center w-100"
                        >
                          <div className="guest-card d-flex align-items-center">
                            <span
                              className="guests-input__ctrl minus"
                              id="children-subs-btn"
                              onClick={(e) =>
                                handleChildrenCountChange(
                                  e,
                                  -1,
                                  "childrenCount"
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </span>
                            {/* /.guests-input__ctrl */}
                            <span className="guests-input__value w-100">
                              <span id="guests-count-children">
                                <input
                                  type="hidden"
                                  value={formData.childrenCount}
                                />
                                {formData.childrenCount}
                              </span>
                            </span>
                            {/* /.guests-input__value */}
                            <span
                              className="guests-input__ctrl plus"
                              id="children-add-btn"
                              onClick={(e) =>
                                handleChildrenCountChange(
                                  e,
                                  +1,
                                  "childrenCount"
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </span>
                            {/* /.guests-input__ctrl */}
                          </div>
                        </Button>
                      </div>
                    </div>

                    {/* <Collapse in={open}> */}
                    <div className="mt-3" id="example-collapse-text">
                      {/* <Scrollbars autoHeight autoHeightMax={150} onUpdate={handleUpdate}>
                      <div className="w-full overflow-hidden" style={{ paddingRight: `${paddingRight}px`,}}> */}
                      {formData?.childrenAges?.length > 0 && (
                        <label>Child Age</label>
                      )}
                      <div className="row g-3">
                        {formData?.childrenAges?.map((age, index) => (
                          <div className="col-6 col-sm-4" key={index}>
                            <div className="guest-card w-100 d-flex align-items-center">
                              <span
                                className="guests-input__ctrl minus"
                                id="children-subs-btn"
                                onClick={() => handleChildAgeChange(index, -1)}
                              >
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </span>
                              {/* <Form.Control type='number' readOnly value={age} className="form-control shadow-none text-center" /> */}

                              <span className="guests-input__value w-100">
                                <span id="guests-count-children">
                                  {/* /.guests-input__value */}
                                  <input type="hidden" value={age} />
                                  {age}
                                </span>
                              </span>
                              {/* /.guests-input__ctrl */}
                              <span
                                className="guests-input__ctrl plus"
                                id="children-add-btn"
                                onClick={() => handleChildAgeChange(index, 1)}
                              >
                                {" "}
                                <FontAwesomeIcon icon={faAngleRight} />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* </div>
                      </Scrollbars> */}
                    </div>
                    {/* </Collapse> */}
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className="ahr-guests-save-btn">
              <Button
                type="button"
                variant="primary theme-btn fw-medium text-white text-uppercase border-theme"
                onClick={(e) => handleGuestsSubmit(e)}
              >
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default BookNow;